import React from "react";

const Button = ({ btnText, btnBgColor, btnColor, btnRadius, btnPadding , hoverColor, btnPaddingLeft, btnPaddingRight, handleClick}) => {
  return (
    <button
      className="custom-button  cursor-pointer px-2"
      style={{
        backgroundColor: btnBgColor,
        color: btnColor,
        borderRadius: btnRadius,
        padding: btnPadding,
        hover:hoverColor,
        paddingLeft: btnPaddingLeft,
        paddingRight: btnPaddingRight
      }}
      onClick={handleClick}
    >
      
      {btnText}
    </button>
  );
};

export default Button;
